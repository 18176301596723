import React from "react";
import IndexPage from "./index"

const IndexPageDE = () => (
  <IndexPage i18n="de"/>
);

export default IndexPageDE;


